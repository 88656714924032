@import '../src/assets/scssStyles/variables';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../src/assets/scssStyles/font';
@import '../src/assets/scssStyles/global';






.header{
    color: $base-color;

}
.w-20 {
    width: 20%;
  }
  
  .w-10 {
    width: 10%
  }
  
  .w-40 {
    width: 40%;
  }

  .w-55 {
    width: 55%;
  }
  .mw-30 {
    width: 30vw;
  }