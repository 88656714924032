$base-color: #c6538c;
$app-bg:#F3F6F9;
$border-dark: rgba($base-color, 0.88);
$background-page-color: #e5e5e5;
$active-field-bg:#EEFEF1;
$yara-blue:#133F7A;
$error-color:#FF4A6B;
$error-text-color:#BC001F;
$table-header-color:#8B90A0;
$font-color-ligth:#505565;
$font-color-dark:#232735;
$border-bttm-light : #E5E5E5;
$light-gray:#8B90A0;
$button-bg:#1370BC;




