@import "../../assets/scssStyles/variables";
@import "../../assets/scssStyles/global";
@import "../CreateFertigationPlan/CreateFertigationPlan.module.scss";

.AddProcduct {
  font-family: inherit;
  left: 60%;
  .DialogTitle{
    border-bottom: 1px solid #ccc;
  }
  @media (max-width:767px){
    position:absolute;
    transform: translateX(-50%) scale(.9);
    bottom: 5px;
    left: 150px;
  }




.popup {
  // position: relative !important;
  background-color: #f3f6f9;
  inset: auto 0px 0px auto !important;
  margin: 0px !important;
  transform: translate3d(-894.167px, 595px, 0px) !important;
  position: absolute !important;
  // transform: translate3d(400px, 700px, 0px) !important;
}
._addProd{
  position: relative;
  &::after{
    display: block;
    content: "|";
    color:#fff;
  }
  &::before{
    display: block;
    content: "--";
    color:#fff;
    position: absolute;
    left: 5px;
    top: -3px;
  }
  background-color: transparent;
  border: transparent;
  box-shadow:0px 0px 0px transparent;
  &:hover{
    // background-color: transparent !important;
    box-shadow:0px 0px 0px transparent !important;
  }
  background-color: #0c559c;
  max-width:20px;
  min-width: 20px;
  height:20px;
  padding: 0px !important;
  border-radius: 40px;
  span{
    position: absolute;
    left:121%;
    color: #0c559c;
    white-space: nowrap;
    font-size: 15px;
    text-transform:none;
  }
}

}
.addProForm{
  input{
    background-color: #f7f7f7;
    border-radius: 0px;
    border:1px solid transparent;
    border-bottom: 1px solid #ccc;
    &:focus{
      border: 0px solid transparent;
      border-bottom: 1px solid #ccc;
      box-shadow: 0px 0px 0px 0px !important;
    }
  }
}
.error {
  color: rgb(235, 0, 0) !important;
  font-size: 13px;
}
.addPro{
  &:hover{
    color: #fff;
  }
}
  @media (min-width:768px){
    [role="tooltip"]{
      // left: 50% !important;
      // top:50% !important;
      // bottom:40px !important;
      // right:auto !important;
      // transform: translateY(-50%) !important;
      z-index: 2;
  }
}