
@font-face {
    font-family: 'Noto Sans Bold';
    src: url('../icons/NotoSans-bold/NotoSans-Bold.ttf'); /* IE9 Compat Modes */
    src: local('Noto Sans Bold'), local('NotoSansBold'),
         url('../icons/NotoSans-bold/NotoSans-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../icons/NotoSans-bold/NotoSans-Bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('../icons/NotoSans-bold/NotoSans-Bold.woff') format('woff'), /* Modern Browsers */
         url('../icons/NotoSans-bold/NotoSans-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../icons/NotoSans-bold/NotoSans-Bold.svg#NotoSansBold') format('svg'); /* Legacy iOS */
  }


@font-face {
    font-family: 'Noto Sans BoldItalic';
    src: url('../icons/NotoSans-BoldItalic/NotoSans-BoldItalic.ttf'); /* IE9 Compat Modes */
    src: local('Noto Sans BoldItalic'), local('NotoSansBoldItalic'),
         url('../icons/NotoSans-BoldItalic/NotoSans-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../icons/NotoSans-BoldItalic/NotoSans-BoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../icons/NotoSans-BoldItalic/NotoSans-BoldItalic.woff') format('woff'), /* Modern Browsers */
         url('../icons/NotoSans-BoldItalic/NotoSans-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../icons/NotoSans-BoldItalic/NotoSans-BoldItalic.svg#NotoSansBoldItalic') format('svg'); /* Legacy iOS */
  }


@font-face {
    font-family: 'Noto Sans Italic';
    src: url('../icons/NotoSans-Italic/NotoSans-Italic.ttf'); /* IE9 Compat Modes */
    src: local('Noto Sans Italic'), local('NotoSansItalic'),
         url('../icons/NotoSans-Italic/NotoSans-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../icons/NotoSans-Italic/NotoSans-Italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../icons/NotoSans-Italic/NotoSans-Italic.woff') format('woff'), /* Modern Browsers */
         url('../icons/NotoSans-Italic/NotoSans-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../icons/NotoSans-Italic/NotoSans-Italic.svg#NotoSansBoldItalic') format('svg'); /* Legacy iOS */
  }

@font-face {
    font-family: 'Noto Sans Regular';  
    src: url('../icons/NotoSans-Regular/NotoSans-Regular.ttf'); /* IE9 Compat Modes */
    src: local('Noto Sans Regular'), local('NotoSansRegular'),
         url('../icons/NotoSans-Regular/NotoSans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../icons/NotoSans-Regular/NotoSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../icons/NotoSans-Regular/NotoSans-Regular.woff') format('woff'), /* Modern Browsers */
         url('../icons/NotoSans-Regular/NotoSans-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../icons/NotoSans-Regular/NotoSans-Regular.svg#NotoSansRegular') format('svg'); /* Legacy iOS */
  }

  .noto-sans-bold {
    font-family: "Noto Sans Bold";
   }
   
   .noto-sans-bold-italic {
    font-family: "Noto Sans BoldItalic";
   }
   .noto-sans-italic {
    font-family: "Noto Sans Italic";
   }
   .noto-sans-regular {
    font-family: "Noto Sans Regular";
   }

 