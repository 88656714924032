@import '../../../assets/scssStyles/variables';
.ConvertedTab {
    .f14{
        font-size: 14px;
    }
    table._calTable{
        &.Dt1{
            table-layout: fixed;
        }
        td{
            text-align: left;
            input{
                border:1px solid #C8CDDC;
                border-radius: 4px;
                width: 83%;
                display: block;
                margin-left: auto;
                margin-right: auto;
                background-color: #EEFEF1;
                font-size: 14px;
                &:focus{
                    border-color: #1370BC;                    ;
                }
            }
        }
        thead{
            background-color: #F3F6F9;
            strong{
                font-size: 14px;
            }
            small{
                font-size: 13px;
                color: rgb(107, 107, 107);
            }
            
            tr{
                td:nth-child(2){
                    text-align: right;
                    padding-right: 15px;
                }
                td:nth-child(3){
                    text-align: center;
                }
                td:nth-child(4){
                    text-align: center;
                }
            }
            .headerTable1:not(.innerTable3),.headerTable2:not(.innerTable3){
                max-width: 82%;
                width: 470px;
                margin: auto;
                tbody{
                    tr{
                        td{
                            padding-right: 0px;
                            width:55px;
                            max-width: 55px;
                            min-width: 55px;
                            text-align: center;
                            padding-left: 0px;
                        }
                    }
                }
            }
        }
        & table{
            td{
                font-size: 13px;
            }
        }
        tbody{
            tr{
                td:nth-child(1){
                    font-size: 13px;
                }
                &.manualCal{
                    .floatLeft{
                        float: left;
                    }
                }
                td:nth-child(1){
                    padding-left: 9px;
                }
                td{
                    table{
                        table-layout: fixed;
                        max-width: 82%;
                        width: 470px;
                        tr{
                            td{
                                padding-left: 0px !important;
                                padding-right: 0px !important;
                                width: 55px;
                                max-width: 55px;
                                min-width: 55px;
                                text-align: center;
                            }
                        }
                        margin: auto;
                    }
                }
            }
        }
     }
    h3{
        font-size: 23px;
        strong{
            font-weight: 800;
        }
        ~button{
            background-color: $button-bg;
            font-weight: bold;
        }
    }
   .tabs{
       border-bottom: 2px solid #f0f0f0;
       span{
           cursor: default;
           &:hover{
               font-weight: bold;
           }
       }
   }
   .active{
       font-weight: bold;
   }
   ._chBox{
       width:30px !important;
       border: 1px solid #8B90A0;
       background-color: #EEFEF1 !important;
   }

   ._totKgR{
       background-color: #F9F9F9;
           width: 470px;
           max-width:82%;
           margin: auto;
       
       div{
        display: inline-block;
        width: 16.6%;
        font-size: 13px;
        text-align: center;
       }
     padding: 8px;
   }
   ._toKg2{
    background-color: #F9F9F9;
   }
@media (max-width:768px){
    ._calTable3{
        width: 100%;
    }
   }
}