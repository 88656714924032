.SidebarButtons {
    text-decoration: none;
    .barLogo,.calcLogo{
        width: 32px;
        height: 32px;
        background-image: url(../../../assets/icons/icons/bars.png);
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .sideBbttn{
        position: absolute;
        left: 9px;
        width: 84%;
        cursor: pointer;
        text-decoration: none;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
    .btnActive.active{
        background-color: #0C559C;
    }
    .calculate{
        top:33%;
        &.btnActive.active{
         top:32%;  
        }
        text-decoration: none;
        .calcLogo{
            background-image: url(../../../assets/icons/icons/icon.svg);
        }
    }
    ._ferg{
        top:20%;
        &.btnActive.active{
          top:20%;  
       }
    }
    ._YaraRega{
        background-image: url(../../../assets/icons/icons/yaraRega.png);
        width: 100%;
        height: 28px;
        background-position: 0px 0px;
        background-size: 100%;
        background-repeat: no-repeat;
        // position: absolute;
        // top:30%;
        // left:10px;
    }
}