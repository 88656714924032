@import '../../assets/scssStyles/variables';
.FertigationSpecification {
    table._calTable{
        td{
            text-align: left;
            input{
                border:1px solid #C8CDDC;
                border-radius: 4px;
                width: 83%;
                display: block;
                margin-left: auto;
                margin-right: auto;
                background-color: #EEFEF1;
                font-size: 14px;
                &:focus{
                    border-color: #1370BC;                    ;
                }
            }
        }
        thead{
            background-color: #F3F6F9;
            strong{
                font-size: 14px;
            }
            small{
                font-size: 13px;
                color: rgb(107, 107, 107);
            }
            
            tr{
                td:nth-child(2){
                    text-align: right;
                    padding-right: 15px;
                }
                td:nth-child(3){
                    text-align: center;
                }
                td:nth-child(4){
                    text-align: center;
                }
            }
            .headerTable1,.headerTable2{
                max-width: 82%;
                width: 470px;
                margin: auto;
                tbody{
                    tr{
                        td{
                            padding-right: 0px;
                            width:55px;
                            max-width: 55px;
                            min-width: 55px;
                            text-align: center;
                            padding-left: 0px;
                        }
                    }
                }
            }
        }
        & table{
            td{
                font-size: 13px;
            }
        }
        tbody{
            tr{
                td:nth-child(1){
                    font-size: 13px;
                }
                &.manualCal{
                    .floatLeft{
                        float: left;
                    }
                }
                td:nth-child(1){
                    padding-left: 20px;
                }
                td{
                    table{
                        table-layout: fixed;
                        max-width: 82%;
                        width: 470px;
                        tr{
                            td{
                                padding-left: 0px !important;
                                padding-right: 0px !important;
                                width: 55px;
                                max-width: 55px;
                                min-width: 55px;
                                text-align: center;
                            }
                        }
                        margin: auto;
                    }
                }
            }
        }

        table._calTable3{
            width: 66.5% !important;
            table-layout: auto !important;
            float: right;
            td{
                padding: 4px;
                border-right:1px solid rgb(222 226 230);
                white-space: nowrap;
                input{
                    border-bottom-color: #ccc !important;
                }
            }
            tr:nth-child(1){
                background-color: #F9F9F9;
                td:nth-child(1){
                 padding-left: 10px !important;
                 width: 25%;
                }
            }
            tr:nth-child(2){
                td{ 
                    &:nth-child(1){
                        width: 25%;
                    }
                    &:nth-child(2){
                        &.innerTable3{
                            table{
                                table-layout: fixed;
                                max-width: 82%;
                                width: 470px;
                                tr{
                                    td{
                                        width: 55px;
                                        min-width: 55px;
                                        max-width: 55px;
                                        margin-left: 0px;
                                        padding-left: 0px !important;
                                        padding-right: 0px !important;
                                        margin-right: 0px;
                                        &:nth-last-child(1){border-right: transparent;}
                                        input{
                                          text-align: center;
                                        }
                                    }
                                }
                            }
                            width: 100% !important;
                            margin-left: 0px;
                        }
                    }
                    background-color: #EEFEF1;
                    padding-left: 10px !important;
                    width: 40%;
                }
            }
        }
     }
    h3{
        font-size: 23px;
        strong{
            font-weight: 800;
            white-space: nowrap;
        }
        ~button{
            background-color: $button-bg;
            font-weight: bold;
        }
    }
   .tabs{
       border-bottom: 2px solid #f0f0f0;
       span{
           cursor: default;
        //    &:hover{
            //    font-weight: bold;
        //    }
       }
   }
   .active{
       font-weight: bold;
       border-bottom: 2px solid #000;
   }
   .help{
        background-image: url(../../assets/icons/icons/help.svg);
        background-repeat: no-repeat;
        width: 35px;
        height: 35px;
        background-size: 100%;
        vertical-align: text-top;
        text-decoration:none;
        &:hover,&:visited,&:active{
            text-decoration: none;
        }
   }
    @media (max-width:768px){
        ._calTable3{
            width: 100%;
        }
        .h3{
            font-size: 18px;
            width: 100%;
        }
        .tabs{
            span{
                font-size: 14px;
            }
        }
    }
}