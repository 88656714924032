@import "./variables";

body {
  font-family: "Noto Sans Regular" !important;
}
.app_bg {
  background-color: $app-bg;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.sideBbttons {
  top: 100px;
  width: auto;
}
.fullHeight {
  height: 100vh;
}
.contentHeight {
  height: 87vh;
}
.createFgH {
  height: 88vh;
}
@media (min-width: 1441px) {
  .createFgH {
    height: 90vh;
  }
}
.fixFromTop {
  position: sticky;
  top: 0px;
}

.CreateFertigationPlan.fertigation,
.EditFertigationPlan.fertigation {
  .mainFerPlanForm {
    max-width: 360px;
    min-width: 360px;
  }
  background-color: #f3f6f9;
  // min-height: 100%;
  .mainFerPlan {
    transform: scale(0.95);
    // margin-top: -15px;
  }
  .h2 {
    font-style: normal;
    font-size: 20px;
    line-height: 40px;
    // transform: scale(0.8);
    color: #232735;
    // margin-top: -15px !important;
  }

  .input,
  .inputColorError {
    font-size: 12px;
    line-height: 20px;
    color: #333;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    border-bottom: 1px solid #1370bc;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #333 !important;
  }

  .inputColor {
    color: #333;
  }

  .inputColorError {
    color: $error-text-color;
    text-align: left;
    margin-top: 0px;
  }

  .text {
    background: #ffffff;
    border: 1px solid #1370bc;
    box-sizing: border-box;
    height: 33px;
    & > div::after {
      border-bottom: 1px solid #1370bc;
    }
    & > div:hover:before,
    & > div::after {
      border-bottom: 0px solid transparent;
    }
    & > div {
      &:hover::before {
        border-bottom: 1px solid #1370bc;
      }
      input {
        padding-left: 10px;
        font-size: 15px;
        height: 23px;
        color: #333;
        text-transform: capitalize;
        &:focus {
          background-color: #eefef1;
        }
      }
      input[name="email"] {
        text-transform: lowercase !important;
      }
    }
    &.isDisabled,
    &.isDisabled > div::after,
    &.isDisabled > div:hover::before {
      border: 1px solid #cccccc;
    }
  }

  .textError {
    border: 1px solid $error-color;
    box-sizing: border-box;
    height: 33px;
    background: #ffffff;
    width: 100%;
    & > div::after {
      border-bottom: 1px solid $error-color;
    }
    & > .div:hover:before,
    & > div::after {
      border-bottom: 0px solid transparent;
    }
    & > div {
      &:hover::before {
        border-bottom: 1px solid $error-color;
      }
      input {
        padding-left: 10px;
        &:focus {
          background-color: #eefef1;
        }
      }
    }
    & > p {
      margin-top: 0px !important;
      margin-left: -1px;
    }
  }

  .textFull {
    background: #ffffff;
    border: 1px solid #1370bc;
    box-sizing: border-box;
    height: 33px;
  }
  .f12 {
    font-size: 12px !important;
  }
  .inputFname {
    font-size: 12px;
    width: 100%;
    height: 20px;
  }
  .inputLname {
    font-size: 12px;
    width: 100%;
    height: 20px;
  }
  .inputCountry {
    width: 100%;
    height: 20px;
  }
  .inputRegion {
    width: 100%;
    height: 20px;
  }
  .inputCrop {
    width: 100%;
    height: 20px;
  }

  .inputArea {
    width: 100%;
    height: 20px;
  }
  .inputTree {
    width: 100%;
    height: 20px;
  }
  .inputYield {
    width: 100%;
    height: 20px;
  }

  .checkBox {
    background: #ffffff;
    border: 1px solid #1370bc;
    box-sizing: border-box;
  }

  .buttonRequest {
    background: #1370bc;
    border: 1px solid #1370bc;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Noto Sans Regular;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    height: 40px;
    text-decoration-color: #ffffff;
    text-transform: capitalize;
  }
  @media (max-width: 768px) {
    .mainFerPlanForm {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

@media (min-width:768px){
  .specificBtton{
    min-width: 130px !important;
    float: right;
  }
}

.selectError,
.selectText {
  width: 100px;
  font-size: 12px !important;
}

.selectStyle {
  height: 33px;
  background-color: #fff;
  fieldset {
    border: 0px solid transparent;
    border-width: 0px !important;
  }
  font-size: 14px !important;
  color: #555 !important;
}
.borderNone {
  border: 0px solid transparent !important;
}
.activeField {
  background-color: $active-field-bg;
}
._soA {
  & > div {
    margin-right: 35px;
    @media (max-width: 360px) {
      margin-right: 45px;
    }
  }
  border-left: 1px solid #e9e9eb !important;
}
.reqF {
  color: $error-text-color !important;
  margin-bottom: -18px !important;
}
.f12 {
  font-size: 12px;
}
.borderRed {
  border-color: $error-color !important;
}
.borderRadius0 {
  border-radius: 0px !important;
}
.border1pxBlue {
  border: 1px solid #1370bc;
}
.disabledopacitu {
  opacity: 0.5;
  &:hover {
    cursor: not-allowed;
  }
  & > div {
    & > div:hover {
      cursor: not-allowed;
    }
  }
  & > div {
    & > input:hover {
      cursor: not-allowed;
    }
  }
}

.border-bottom {
  border-bottom: 1px solid #e9e9eb !important;
}
.border-bottom-th-color {
  border-bottom: 1px solid $table-header-color !important;
}
.yaraLogo {
  width: 42px;
  height: 42px;
  background-image: url(../../assets/icons/icons/sidebar-logo.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.fPvSb {
  font-size: 11px;
  line-height: 13px;
  word-break: break-word;
  color: #fff;
}
@media (max-width: 767px) {
  .fPvSb {
    margin-left: 0px;
  }
}
._logOut {
  &,
  &:hover,
  &:visited,
  &:focus {
    background-color: $button-bg;
    color: #ffffff;
    border-radius: 5px;
  }
}
.main_btn {
  background-color: $button-bg;
}
.btn_disabled {
  background-color: $button-bg !important;
  border: 1px solid $button-bg !important;
}
.btnActive.active {
  background-color: #0c559c;
}

.background-blur {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba($color: #c1bdbd, $alpha: 0.7);
  backdrop-filter: blur(28px);
  z-index: 1;
}
.centerOfPage{
  display: flex;
  justify-content: center;
  align-items: center;
  // transform: translate(-50%);
  width: 80vw;
  height: 80vh;
  max-width: 100%;
  margin: auto;
  text-align: center;
}
.canc{
    position: absolute;
    color: #0c559c;
    &::before{
      color: #0c559c;
    }
    right: 13px;
    top: 7px;
    width: auto;
    transform: scaleX(1.2);
    &:hover{
      cursor: default;
    }
}
.previous{
  button{
    border-radius: 2px;
    text-transform: none;
  }
}
