@import "../../assets/scssStyles/variables";
@import "../../assets/scssStyles/global";
.CreateFertigationPlan.fertigation {
  overflow-y: auto;
  @media (max-width: 767px) {
    form {
      min-width: 100%;
    }
  }
}
._yaraCheck {
  text-align: left;
  font-size: 12px;
  display: flex;
  input {
    display: block;
    border-radius: 0px;
    width: 19px;
    min-width: 19px;
    height: 19px;
    border: 1px solid #1370bc;
    margin-right: 10px;
    border-radius: 0px !important;
  }
  &.errorCheckBox {
    input {
      border: 1px solid #e90707 !important;
    }
  }
}
// .h4{
  
// }